import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';
import { useAuth } from '../context/AuthContext';
import { CircularProgress, Box, Typography } from '@mui/material';

const AuthCallback = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const { fetchCreditBalance, setUser } = useAuth();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        // 1. Parse the hash fragment
        const hashFragment = window.location.hash.substring(1); // remove leading '#'
        console.log('[AuthCallback] Hash fragment:', hashFragment);
        
        // 2. Parse the hash fragment manually since URLSearchParams doesn't handle hash fragments well
        const params = {};
        hashFragment.split('&').forEach(pair => {
          const [key, value] = pair.split('=');
          params[key] = decodeURIComponent(value);
        });
        console.log('[AuthCallback] Parsed params:', { 
          ...params,
          access_token: params.access_token ? '***' : undefined, // Hide sensitive data in logs
          refresh_token: params.refresh_token ? '***' : undefined
        });

        // 3. Extract the tokens
        const accessToken = params['access_token'];
        const refreshToken = params['refresh_token'];
        const tokenType = params['token_type'];
        const expiresIn = params['expires_in'];

        console.log('[AuthCallback] Tokens extracted:', { 
          hasAccessToken: !!accessToken,
          hasRefreshToken: !!refreshToken,
          tokenType,
          expiresIn
        });

        // Only proceed if we have an access token
        if (accessToken) {
          // 4. Use Supabase's setSession() to store tokens in its local storage
          console.log('[AuthCallback] Setting Supabase session...');
          const { data, error: supabaseError } = await supabase.auth.setSession({
            access_token: accessToken,
            refresh_token: refreshToken,
          });

          if (supabaseError) {
            console.error('[AuthCallback] Supabase setSession error:', supabaseError);
            throw new Error(supabaseError.message);
          }
          console.log('[AuthCallback] Supabase session set successfully');

          // 5. Update user state in AuthContext
          if (data?.user) {
            console.log('[AuthCallback] Updating user state in AuthContext');
            setUser(data.user);
          }

          // 6. Store tokens in localStorage
          localStorage.setItem('token', accessToken);
          console.log('[AuthCallback] Token stored in localStorage');

          // 7. Fetch user's credit balance
          console.log('[AuthCallback] Fetching credit balance...');
          await fetchCreditBalance();
          console.log('[AuthCallback] Credit balance fetched');

          // 8. Handle redirect
          const redirectPath = localStorage.getItem('redirectAfterLogin');
          console.log('[AuthCallback] Redirect path:', redirectPath || '/');
          
          if (redirectPath) {
            localStorage.removeItem('redirectAfterLogin');
            navigate(redirectPath);
          } else {
            navigate('/');
          }
        } else {
          console.log('[AuthCallback] No access token in URL hash - this is expected on subsequent renders');
        }

      } catch (err) {
        console.error('[AuthCallback] Error:', err);
        setError(err.message);
      }
    };

    handleCallback();
  }, [navigate, fetchCreditBalance, setUser]);

  if (error) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        p={3}
      >
        <Typography color="error" variant="h6" align="center" gutterBottom>
          Authentication Error
        </Typography>
        <Typography color="error" variant="body1" align="center">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      p={3}
    >
      <CircularProgress />
      <Typography variant="h6" sx={{ mt: 2 }}>
        Completing sign in...
      </Typography>
    </Box>
  );
};

export default AuthCallback;
