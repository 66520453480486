import React, { useState, useEffect, useRef } from 'react';
import { Box, Paper, Typography, IconButton, Snackbar, Alert, Stack } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InpaintingTool from './components/InpaintingTool';
import InpaintingImageGrid from './components/InpaintingImageGrid';
import { apiRequest } from '../../utils/apiConfig';

const ViewImage = () => {
  const { fetchCreditBalance } = useAuth();
  const [searchParams] = useSearchParams(); // Remove unused setSearchParams
  const navigate = useNavigate();
  const versionsPerPage = 8;

  // URL parameters
  const imageId = searchParams.get('id');
  const imageUrl = searchParams.get('imageUrl');
  const prompt = searchParams.get('prompt');

  // Component state
  const [currentImageUrl, setCurrentImageUrl] = useState('');
  const [currentPrompt, setCurrentPrompt] = useState('');
  const [isInpainting, setIsInpainting] = useState(false);
  const [error, setError] = useState('');
  const [inpaintingVersions, setInpaintingVersions] = useState([]);
  const [isLoadingVersions, setIsLoadingVersions] = useState(false);
  const [activeTask, setActiveTask] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isCompleting, setIsCompleting] = useState(false);
  const abortControllerRef = useRef(null);

  // Debug state changes
  useEffect(() => {
    console.log('State update:', {
      inpaintingVersions,
      page,
      totalPages,
      paginatedCount: inpaintingVersions.slice((page - 1) * versionsPerPage, page * versionsPerPage).length,
      activeTask,
      hasQueuedVersion: inpaintingVersions.some(v => v.isQueued)
    });
  }, [inpaintingVersions, page, totalPages, versionsPerPage, activeTask]);

  // Reset all state
  const resetState = React.useCallback((newImageUrl = '', newPrompt = '') => {
    setCurrentImageUrl(newImageUrl);
    setCurrentPrompt(newPrompt);
    setInpaintingVersions([]);
    setPage(1);
    setTotalPages(1);
    setActiveTask(null);
    setIsInpainting(false);
    setError('');
    setIsLoadingVersions(false);
  }, []);

  // Fetch versions for the current image
  const fetchVersions = React.useCallback(async () => {
    if (!imageId) return;

    setIsLoadingVersions(true);
    try {
      const response = await apiRequest(`/api/generate-image/inpaint/history/${imageId}`);
      
      // If we have an active task, preserve the queued version
      setInpaintingVersions(prev => {
        const queuedVersion = prev.find(v => v.isQueued);
        if (queuedVersion) {
          console.log('Preserving queued version during fetch:', queuedVersion);
        }
        
        // Sort and filter the new versions
        const newVersions = response.data.sort((a, b) => {
          // Keep original at the end
          if (a.isOriginal) return 1;
          if (b.isOriginal) return -1;
          // Sort other versions by date (newest first)
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        // If we have a queued version, add it at the start
        const finalVersions = queuedVersion 
          ? [queuedVersion, ...newVersions]
          : newVersions;

        setTotalPages(Math.ceil(finalVersions.length / versionsPerPage));
        return finalVersions;
      });
    } catch (err) {
      setError('Failed to load inpainting versions');
    } finally {
      setIsLoadingVersions(false);
    }
  }, [imageId, versionsPerPage]);

  // Reset state, cleanup, and fetch versions when image changes
  useEffect(() => {
    // Skip initialization if we have an active inpainting task
    if (activeTask) {
      console.log('Skipping initialization due to active task:', activeTask);
      return;
    }

    const init = async () => {
      console.log('Initializing state with:', { imageId, imageUrl, prompt });
      
      // Reset state first
      resetState(imageUrl || '', prompt || '');

      // Get the actual image dimensions
      const img = new Image();
      img.src = imageUrl;
      await new Promise((resolve) => {
        img.onload = () => {
          resolve();
        };
      });

      // Create the original version object with actual dimensions
      const originalVersion = {
        id: imageId || 'original',
        prompt: prompt || '',
        imageUrl: imageUrl,
        createdAt: new Date(0).toISOString(),
        isOriginal: true,
        width: img.naturalWidth,
        height: img.naturalHeight
      };

      // Then fetch versions if we have an imageId
      if (imageId) {
        try {
          await fetchVersions();
          // Ensure the original version is updated even after fetching versions
          setInpaintingVersions(prev => {
            const queuedVersion = prev.find(v => v.isQueued);
            const withoutOriginal = prev.filter(v => !v.isOriginal && !v.isQueued);
            return queuedVersion 
              ? [queuedVersion, ...withoutOriginal, originalVersion]
              : [...withoutOriginal, originalVersion];
          });
        } catch (error) {
          // If fetching fails, at least set up the original version
          setInpaintingVersions([originalVersion]);
        }
      } else if (imageUrl) {
        // If no versions yet but we have an imageUrl, set up initial state with just the original
        setInpaintingVersions([originalVersion]);
      }
    };

    init();
  }, [imageId, imageUrl, prompt, resetState, activeTask, fetchVersions]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Poll for inpainting status when there's an active task
  useEffect(() => {
    if (!activeTask) return;

    console.log('Starting polling for task:', activeTask);
    
    // Create new AbortController for this polling session
    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;

    const pollInterval = setInterval(async () => {
      // Skip if we're already processing a completion
      if (isCompleting) {
        console.log('Skipping poll as completion is in progress');
        return;
      }
      try {
        const statusResponse = await apiRequest(
          `/api/generate-image/inpaint/status/${activeTask.taskId}/${activeTask.requestId}`
        );
        console.log('Polling status response:', statusResponse.data);

        if (statusResponse.data.status === 'completed') {
          clearInterval(pollInterval);
          console.log('Inpainting completed:', statusResponse.data);
          
          // Set completing flag to prevent duplicate processing
          setIsCompleting(true);
          
          try {
            // Refresh credit balance
            await fetchCreditBalance();
            // Get the completed version details with actual dimensions
            const img = new Image();
            img.src = statusResponse.data.images[0].url;
            await new Promise((resolve) => {
              img.onload = () => {
                resolve();
              };
            });

            const completedVersion = {
              id: activeTask.taskId,
              prompt: activeTask.prompt,
              imageUrl: statusResponse.data.images[0].url,
              createdAt: new Date().toISOString(),
              width: img.naturalWidth,
              height: img.naturalHeight
            };

            console.log('Updating versions with completed version:', completedVersion);
            // Update versions state with completed version
            setInpaintingVersions(prev => {
              const originalVersion = prev.find(v => v.isOriginal);
              // Keep other queued versions if any
              const otherVersions = prev.filter(v => 
                !v.isOriginal && 
                v.id !== activeTask.taskId && 
                (!v.isQueued || v.id !== activeTask.taskId)
              );
              return [completedVersion, ...otherVersions, originalVersion].filter(Boolean);
            });
          } catch (error) {
            // If updating fails, remove the queued version
            setInpaintingVersions(prev => {
              const originalVersion = prev.find(v => v.isOriginal);
              const otherVersions = prev.filter(v => !v.isOriginal && v.id !== activeTask.taskId);
              return [...otherVersions, originalVersion].filter(Boolean);
            });
            setError('Failed to update completed version');
          } finally {
            setActiveTask(null);
            setIsInpainting(false);
            setIsCompleting(false);
          }
        } else if (statusResponse.data.status === 'failed') {
          clearInterval(pollInterval);
          throw new Error('Inpainting failed');
        }
      } catch (err) {
        clearInterval(pollInterval);
        setError(err.message || 'Failed to check inpainting status');
        setActiveTask(null);
        setIsInpainting(false);
        
        // Remove the queued version on error
        setInpaintingVersions(prev => {
          const originalVersion = prev.find(v => v.isOriginal);
          const otherVersions = prev.filter(v => !v.isOriginal && v.id !== activeTask.taskId);
          return [...otherVersions, originalVersion].filter(Boolean);
        });
      }
    }, 2000);

    return () => {
      clearInterval(pollInterval);
      // Abort any in-flight requests
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = null;
      }
    };
  }, [activeTask, versionsPerPage, fetchVersions, fetchCreditBalance]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleInpaintingSubmit = async ({ prompt: inpaintingPrompt, maskUrl }) => {
    setIsInpainting(true);
    setError('');

    try {
      const response = await apiRequest('/api/generate-image/inpaint', {
        method: 'POST',
        body: {
          prompt: inpaintingPrompt,
          imageUrl: currentImageUrl,
          maskUrl,
          originalTaskOutputId: imageId
        }
      });

      console.log('Inpainting submission response:', response.data);

      // Get the original image dimensions
      const img = new Image();
      img.src = currentImageUrl;
      await new Promise((resolve) => {
        img.onload = () => {
          resolve();
        };
      });

      // Create queued version and update state
      const queuedVersion = {
        id: response.data.taskId,
        prompt: inpaintingPrompt,
        isQueued: true,
        createdAt: new Date().toISOString(),
        width: img.naturalWidth,
        height: img.naturalHeight
      };
      console.log('Created queued version:', queuedVersion);

      // Ensure we have the original version
      const originalVersion = {
        id: imageId,
        prompt: currentPrompt,
        imageUrl: currentImageUrl,
        createdAt: new Date(0).toISOString(),
        isOriginal: true,
        width: img.naturalWidth,
        height: img.naturalHeight
      };

      console.log('Current inpainting versions:', inpaintingVersions);
      // Update versions state
      setInpaintingVersions(prev => {
        const existingOriginal = prev.find(v => v.isOriginal);
        const otherVersions = prev.filter(v => !v.isOriginal);
        const newVersions = [queuedVersion, ...otherVersions, existingOriginal || originalVersion].filter(Boolean);
        console.log('Updating versions state:', {
          queuedVersion,
          existingOriginal,
          otherVersions,
          newVersions
        });
        setTotalPages(Math.ceil(newVersions.length / versionsPerPage));
        // Reset to first page to show the queued version
        setPage(1);
        return newVersions;
      });

      // After state is updated, set active task for polling
      setActiveTask({
        taskId: response.data.taskId,
        requestId: response.data.requestId,
        prompt: inpaintingPrompt
      });
    } catch (err) {
      setError(err.message || 'Failed to submit inpainting request');
      setIsInpainting(false);
    }
  };

  // Get paginated versions
  const paginatedVersions = inpaintingVersions.slice(
    (page - 1) * versionsPerPage,
    page * versionsPerPage
  );

  return (
    <Box sx={{ p: 0 }}>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
        <IconButton onClick={handleBack} size="large">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4">Edit Image</Typography>
      </Box>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography variant="body1" color="text.secondary">
          Highlight the part of the image you want to edit
        </Typography>
      </Box>

      <Stack spacing={2}>
        {/* Combined image display and inpainting tool */}
        <Paper sx={{ p: 1, maxWidth: 1000, mx: 'auto' }}>
          <InpaintingTool
            key={currentImageUrl} // Only remount when the image URL changes
            imageUrl={currentImageUrl}
            onSubmit={handleInpaintingSubmit}
            isLoading={isInpainting}
          />
        </Paper>

        {/* Inpainting versions grid */}
        <Box>
          <Typography variant="h6" gutterBottom>
            Previous Edits
          </Typography>
          <InpaintingImageGrid
            images={paginatedVersions}
            isLoading={isLoadingVersions}
            error={error}
            page={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            onImageClick={(image) => {
              if (!image.isQueued && image.imageUrl && !activeTask) {
                console.log('Switching to image for editing:', {
                  from: {
                    url: currentImageUrl,
                    prompt: currentPrompt
                  },
                  to: {
                    url: image.imageUrl,
                    prompt: image.prompt
                  }
                });
                // Just update the current image and prompt for editing
                setCurrentImageUrl(image.imageUrl);
                setCurrentPrompt(image.prompt);
              } else if (activeTask) {
                console.log('Ignoring image click due to active task:', activeTask);
              }
            }}
          />
        </Box>
      </Stack>

      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={() => setError('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ViewImage;
