import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { apiRequest, API_BASE_URL } from '../utils/apiConfig';
import { supabase } from '../utils/supabaseClient';

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [creditBalance, setCreditBalance] = useState(0);

  const checkSession = useCallback(async (token) => {
    try {
      // First check Supabase session
      const { data: { session: supabaseSession } } = await supabase.auth.getSession();
      
        if (supabaseSession) {
          if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] Supabase session valid', supabaseSession);};
          setUser(supabaseSession.user);
          localStorage.setItem('userEmail', supabaseSession.user.email);
          localStorage.setItem('userId', supabaseSession.user.id);
          localStorage.setItem('token', supabaseSession.access_token);
          await fetchUserMetadata(supabaseSession.user.id);
          setLoading(false);
          return;
        }

      // If no Supabase session, check backend session
      if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] No Supabase session, checking backend session with URL:', API_BASE_URL);};
      try {
        const response = await apiRequest('auth/session', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (response.success && response.data?.user) {
          if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] Backend session valid, user authenticated', response.data.user);};
          setUser(response.data.user);
          localStorage.setItem('userEmail', response.data.user.email);
          localStorage.setItem('userId', response.data.user.id);
          await fetchUserMetadata(response.data.user.id);
        } else {
          if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] No user in backend session response, signing out', response);};
          handleSignOut();
        }
      } catch (error) {
        console.error('[Auth] Backend session check failed:', error);
        handleSignOut();
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] AuthProvider mounted, checking token');};
    const token = localStorage.getItem('token');
    if (token) {
      checkSession(token);
    } else {
      setLoading(false);
    }
  }, [checkSession]);

  const fetchUserMetadata = async (userId) => {
    try {
      const response = await apiRequest('credits/balance');
      if (response.success && response.data?.balance !== undefined) {
        setCreditBalance(response.data.balance);
        localStorage.setItem('creditBalance', response.data.balance.toString());
      }
    } catch (error) {
      console.error('[Auth] Error fetching user metadata:', error);
    }
  };

  const fetchCreditBalance = async () => {
    try {
      const response = await apiRequest('credits/balance');
      if (response.success && response.data?.balance !== undefined) {
        setCreditBalance(response.data.balance);
        localStorage.setItem('creditBalance', response.data.balance.toString());
        return response.data.balance;
      }
      return null;
    } catch (error) {
      console.error('[Auth] Error fetching credit balance:', error);
      return null;
    }
  };

  const updateCreditBalance = (newBalance) => {
    setCreditBalance(newBalance);
    localStorage.setItem('creditBalance', newBalance.toString());
  };

  const signUp = async (email, password) => {
    try {
      if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] Attempting signup with backend URL:', API_BASE_URL);};
      const response = await apiRequest('auth/signup', {
        method: 'POST',
        body: { email, password }
      });
      
      if (!response.success) throw new Error(response.error);
      
      // Return success without auto sign-in
      return { data: response.data, error: null };
    } catch (error) {
      console.error('[Auth] Signup error:', error);
      return { error: error.message };
    }
  };

  const signIn = async (email, password) => {
    try {
      if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] Attempting signin with backend URL:', API_BASE_URL);};
      if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] Environment API URL:', process.env.REACT_APP_API_URL);};
      
      // Attempt to sign in directly - our backend now handles all verification checks
      const response = await apiRequest('auth/signin', {
        method: 'POST',
        body: { email, password }
      });

      if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] Signin response received');};
      
      if (!response.success) throw new Error(response.error);

      const { data } = response;
      if (data?.session) {
        if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] Setting user session data');};
        setUser(data.session.user);
        localStorage.setItem('token', data.session.access_token);
        localStorage.setItem('userEmail', data.session.user.email);
        localStorage.setItem('userId', data.session.user.id);
        await fetchUserMetadata(data.session.user.id);
      } else {
        console.error('[Auth] No session data in response');
        throw new Error('Invalid login response');
      }

      return { data, error: null };
    } catch (error) {
      console.error('[Auth] Signin error:', error);
      return { data: null, error: error.message };
    }
  };

  const handleSignOut = async () => {
    if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] Handling signout, clearing session data');};
    
    // Sign out from Supabase if there's a session
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      await supabase.auth.signOut();
    }
    
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userId');
    localStorage.removeItem('creditBalance');
  };

  const signOut = async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        await apiRequest('auth/signout', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      }
      handleSignOut();
      return { error: null };
    } catch (error) {
      console.error('[Auth] Signout error:', error);
      return { error: error.message };
    }
  };

  const resetPassword = async (email) => {
    try {
      if (!process.env.REACT_APP_RESET_PASSWORD_URL) {
        throw new Error('Reset password URL is not configured');
      }

      const response = await apiRequest('auth/forgot-password', {
        method: 'POST',
        body: { 
          email,
          redirectUrl: process.env.REACT_APP_RESET_PASSWORD_URL
        }
      });

      if (!response.success) throw new Error(response.error);
      return { data: response.data, error: null };
    } catch (error) {
      console.error('[Auth] Reset password error:', error);
      return { data: null, error: error.message };
    }
  };

  // Helper functions to get session data
  const getSessionEmail = () => localStorage.getItem('userEmail');
  const getSessionUserId = () => localStorage.getItem('userId');
  const getSessionCreditBalance = () => localStorage.getItem('creditBalance');

  const signInWithGoogle = async () => {
    try {
      if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] Starting Google sign in');};
      
      // Instead of signing out, check and clear existing session only if needed
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        await supabase.auth.signOut();
      }
      
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${window.location.origin}/auth/callback`,
          queryParams: {
            access_type: 'offline',
            prompt: 'select_account'
          }
        }
      });
      
      if (error) {
        console.error('[Auth] Google sign-in initiation error:', error);
        throw error;
      }
      
      // Check if we got a valid response
      if (!data) {
        throw new Error('No response from Google sign in');
      }
      
      if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] Google sign in initiated successfully');};
      return { data, error: null };
    } catch (error) {
      console.error('[Auth] Google signin error:', error);
      return { data: null, error: error.message };
    }
  };

  const value = {
    user,
    setUser,
    creditBalance,
    updateCreditBalance,
    fetchCreditBalance,
    signUp,
    signIn,
    signInWithGoogle,
    signOut,
    resetPassword,
    loading,
    getSessionEmail,
    getSessionUserId,
    getSessionCreditBalance
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
