import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Pagination,
  CircularProgress,
  Alert,
  IconButton,
  Chip,
  Stack
} from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useNavigate } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';

const ImageGrid = ({
  images,
  page,
  totalPages,
  isLoading,
  error,
  onPageChange
}) => {
  const [expandedPrompts, setExpandedPrompts] = React.useState({});
  // Estimate if text needs truncation based on length
  // Average characters per line based on font size and container width
  const CHARS_PER_LINE = 45; // Approximate characters that fit in one line
  const MAX_LINES = 2;
  
  const isTextTruncated = React.useCallback((text) => {
    return text.length > CHARS_PER_LINE * MAX_LINES;
  }, []);

  const togglePrompt = (imageId, event) => {
    event.stopPropagation();
    setExpandedPrompts(prev => ({
      ...prev,
      [imageId]: !prev[imageId]
    }));
  };
  const navigate = useNavigate();

  // Debug images array changes
  useEffect(() => {
    console.log('ImageGrid received new images:', images.map(img => ({
      id: img.id,
      isQueued: img.isQueued,
      imageUrl: img.imageUrl ? 'exists' : 'none'
    })));
  }, [images]);

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 2 }}>
        {error}
      </Alert>
    );
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 200
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!images.length) {
    return (
      <Paper
        sx={{
          mt: 2,
          p: 1,
          textAlign: 'center',
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark'
              ? theme.palette.background.paper
              : theme.palette.grey[50]
        }}
      >
        <Typography variant="body1" color="text.secondary">
          No images generated yet. Try generating one above!
        </Typography>
      </Paper>
    );
  }

  const handleImageClick = (image) => {
    if (!image.isQueued && image.imageUrl) {
      navigate(`/image-generation/view-image?id=${encodeURIComponent(image.id)}&imageUrl=${encodeURIComponent(image.imageUrl)}&prompt=${encodeURIComponent(image.prompt)}`);
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={0}>
        {images.map((image) => (
          <Grid item xs={6} sm={6} md={4} lg={3} key={image.id}
          sx={{
            paddingTop: 0,
            marginLeft:0,
            paddingLeft: 0,
            marginTop: 0,
            paddingRight: 1,
            paddingBottom: 1, 
          }}>
            <Paper
              sx={{
                p: 0,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark'
                    ? theme.palette.background.paper
                    : theme.palette.grey[50]
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  paddingTop: image.height && image.width 
                    ? `${(image.height / image.width) * 100}%` // Maintain original aspect ratio
                    : '100%', // Fallback to square if dimensions not available
                  width: '100%',
                  overflow: 'hidden'
                }}
              >
                {image.isQueued ? (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'dark'
                          ? 'rgba(0, 0, 0, 0.1)'
                          : 'rgba(0, 0, 0, 0.05)',
                      borderRadius: 1
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    {image.isCached && (
                      <Chip
                        icon={<CachedIcon />}
                        label="Cached"
                        size="small"
                        color="primary"
                        sx={{
                          position: 'absolute',
                          top: 8,
                          left: 8,
                          zIndex: 2,
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          '& .MuiChip-label': {
                            color: 'white'
                          },
                          '& .MuiChip-icon': {
                            color: 'white'
                          }
                        }}
                      />
                    )}
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the parent onClick
                        window.open(image.imageUrl, '_blank');
                      }}
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.7)'
                        },
                        zIndex: 2
                      }}
                    >
                      <DownloadIcon />
                    </IconButton>
                    <Box
                      component="img"
                      src={image.imageUrl}
                      alt={image.prompt}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 1,
                        cursor: !image.isQueued ? 'pointer' : 'default',
                        '&:hover': !image.isQueued ? {
                          transform: 'scale(1.02)',
                          transition: 'transform 0.2s'
                        } : {}
                      }}
                      onClick={() => !image.isQueued && image.imageUrl && handleImageClick(image)}
                      onError={(e) => {
                        e.target.style.display = 'none';
                        console.error('Failed to load image:', image.imageUrl);
                      }}
                    />
                  </>
                )}
              </Box>
              <Box sx={{ p: 1 }}>
                <Stack 
                  direction="row" 
                  spacing={1} 
                  alignItems="flex-start"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{
                      ...(expandedPrompts[image.id] ? {} : {
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      })
                    }}
                  >
                    {image.prompt}
                  </Typography>
                  {isTextTruncated(image.prompt) && (
                    <IconButton 
                      size="small" 
                      onClick={(e) => togglePrompt(image.id, e)}
                      sx={{ 
                        padding: 0,
                        marginLeft: 'auto',
                        minWidth: 24
                      }}
                    >
                      {expandedPrompts[image.id] ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
                    </IconButton>
                  )}
                </Stack>
                {image.isCached && (
                  <Typography
                    variant="caption"
                    color="primary"
                    sx={{
                      display: 'block',
                      mt: 0.5,
                      fontSize: '0.7rem'
                    }}
                  >
                    {image.cacheMessage}
                  </Typography>
                )}
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {totalPages > 1 && (
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={(_, value) => onPageChange(value)}
            color="primary"
          />
        </Box>
      )}
    </Box>
  );
};

export default ImageGrid;
