import React, { useRef, useState, useCallback, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Stack, 
  IconButton, 
  TextField,
  Button,
  Typography,
  CircularProgress,
  Tooltip,
  Slider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import BrushIcon from '@mui/icons-material/Brush';
import UndoIcon from '@mui/icons-material/Undo';
import ReplayIcon from '@mui/icons-material/Replay';
import EraserIcon from '@mui/icons-material/CleaningServices';
import PanToolIcon from '@mui/icons-material/PanTool'; 
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

const ToolbarPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
    gap: theme.spacing(0.5),
    '& .MuiIconButton-root': {
      padding: theme.spacing(1)
    },
    '& .MuiTextField-root': {
      maxWidth: '60px'
    }
  }
}));

const CanvasContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  overflow: 'hidden',
  touchAction: 'none' // Prevent all default touch behaviors inside the canvas container
}));

// Visible canvas for showing the combined image + mask
const DisplayCanvas = styled('canvas')(({ tool, isPanning }) => ({
  width: '100%',
  cursor: tool === 'grab' ? (isPanning ? 'grabbing' : 'grab') : 'crosshair',
  touchAction: 'manipulation', // Allow pinch zoom but prevent unwanted scroll
  display: 'block'
}));

// A floating circular preview that follows the mouse/touch
const BrushPreview = styled('div')(({ size, visible }) => ({
  position: 'fixed',
  width: size,
  height: size,
  border: '2px solid rgba(255, 255, 255, 0.8)',
  borderRadius: '50%',
  pointerEvents: 'none',
  display: visible ? 'block' : 'none',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'rgba(255, 255, 255, 0.4)',
  zIndex: 9999
}));

const MIN_ZOOM = 0.5;
const MAX_ZOOM = 5;
const ZOOM_STEP = 0.1;

const InpaintingTool = ({ imageUrl, onSubmit }) => {
  // Refs for the hidden "mask" canvas and the visible "display" canvas
  const maskCanvasRef = useRef(null);
  const displayCanvasRef = useRef(null);
  const imageRef = useRef(null);

  // Basic state
  const [activeTool, setActiveTool] = useState('brush');  // 'brush', 'eraser', 'grab'
  const [brushSize, setBrushSize] = useState(50);
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Zoom/pan
  const [zoom, setZoom] = useState(1);
  const [pan, setPan] = useState({ x: 0, y: 0 });
  const [isPanning, setIsPanning] = useState(false);
  const [lastPanPos, setLastPanPos] = useState(null);

  // Brushing
  const [isDrawing, setIsDrawing] = useState(false);
  const [brushPosition, setBrushPosition] = useState({ x: 0, y: 0 });
  const [isBrushVisible, setIsBrushVisible] = useState(false);

  // Undo/redo stack (stores mask image data)
  const [history, setHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);

  // Store loaded image dimensions so we can size our canvases properly
  const [imageDims, setImageDims] = useState({ width: 0, height: 0 });

  /**
   * Load image on mount (or when imageUrl changes) and size the canvases to match the image.
   */
  useEffect(() => {
    resetState();  // Clear everything whenever the image changes
    const img = new Image();
    img.onload = () => {
      imageRef.current = img;
      setImageDims({ width: img.width, height: img.height });

      // Once loaded, set up the mask canvas size
      if (maskCanvasRef.current) {
        maskCanvasRef.current.width = img.width;
        maskCanvasRef.current.height = img.height;
        const maskCtx = maskCanvasRef.current.getContext('2d', { willReadFrequently: true });
        maskCtx.clearRect(0, 0, img.width, img.height);
      }

      // We also set up the display canvas
      if (displayCanvasRef.current) {
        displayCanvasRef.current.width = img.width;
        displayCanvasRef.current.height = img.height;
      }

      // Initialize history with a blank mask
      if (maskCanvasRef.current) {
        const maskCtx = maskCanvasRef.current.getContext('2d', { willReadFrequently: true });
        const initial = maskCtx.getImageData(0, 0, img.width, img.height);
        setHistory([initial]);
        setHistoryIndex(0);
      }

      drawCanvas(); // Show the image for the first time
    };
    img.src = imageUrl;

    // Cleanup if unmounted
    return () => {
      imageRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl]);

  /**
   * Draw the final composition (image + mask) onto the display canvas.
   */
  const drawCanvas = useCallback(() => {
    const displayCanvas = displayCanvasRef.current;
    const maskCanvas = maskCanvasRef.current;
    if (!displayCanvas || !maskCanvas || !imageRef.current) return;

    const displayCtx = displayCanvas.getContext('2d');
    // Clear the entire display canvas
    displayCtx.clearRect(0, 0, displayCanvas.width, displayCanvas.height);

    // Save and apply pan/zoom transforms
    displayCtx.save();
    displayCtx.translate(-pan.x, -pan.y);
    displayCtx.scale(zoom, zoom);

    // 1) Draw the source image
    displayCtx.drawImage(imageRef.current, 0, 0);

    // 2) Draw the mask (white strokes) on top
    displayCtx.drawImage(maskCanvas, 0, 0);

    // Restore
    displayCtx.restore();
  }, [zoom, pan]);

  /**
   * Whenever zoom or pan changes, re-draw right away so the user sees the update immediately.
   */
  useEffect(() => {
    drawCanvas();
  }, [zoom, pan, drawCanvas]);

  /**
   * Convert screen coords (mouse/touch) to "image coords" inside the mask canvas.
   */
  const getCanvasCoords = useCallback((clientX, clientY) => {
    const displayCanvas = displayCanvasRef.current;
    if (!displayCanvas) return { x: 0, y: 0 };
  
    const rect = displayCanvas.getBoundingClientRect();
  
    // Ratio of internal canvas pixels (displayCanvas.width / displayCanvas.height)
    // to its CSS size (rect.width / rect.height)
    const scaleX = displayCanvas.width / rect.width;
    const scaleY = displayCanvas.height / rect.height;
  
    const xInDisplay = (clientX - rect.left) * scaleX;
    const yInDisplay = (clientY - rect.top) * scaleY;
  
    // Now apply pan and zoom
    const imageX = (xInDisplay + pan.x) / zoom;
    const imageY = (yInDisplay + pan.y) / zoom;
  
    return { x: imageX, y: imageY };
  }, [pan, zoom]);
  

  /**
   * Undo/redo management
   */
  const saveToHistory = useCallback(() => {
    const maskCanvas = maskCanvasRef.current;
    if (!maskCanvas) return;

    const maskCtx = maskCanvas.getContext('2d', { willReadFrequently: true });
    const newImageData = maskCtx.getImageData(0, 0, maskCanvas.width, maskCanvas.height);

    const newHistory = history.slice(0, historyIndex + 1);
    newHistory.push(newImageData);
    setHistory(newHistory);
    setHistoryIndex(newHistory.length - 1);
  }, [history, historyIndex]);

  const undo = useCallback(() => {
    if (historyIndex <= 0) return;
    const maskCanvas = maskCanvasRef.current;
    const maskCtx = maskCanvas.getContext('2d', { willReadFrequently: true });
    
    const newIndex = historyIndex - 1;
    setHistoryIndex(newIndex);
    maskCtx.putImageData(history[newIndex], 0, 0);

    drawCanvas();
  }, [history, historyIndex, drawCanvas]);

  const redo = useCallback(() => {
    if (historyIndex >= history.length - 1) return;
    const maskCanvas = maskCanvasRef.current;
    const maskCtx = maskCanvas.getContext('2d', { willReadFrequently: true });

    const newIndex = historyIndex + 1;
    setHistoryIndex(newIndex);
    maskCtx.putImageData(history[newIndex], 0, 0);

    drawCanvas();
  }, [history, historyIndex, drawCanvas]);

  const clearCanvas = useCallback(() => {
    const maskCanvas = maskCanvasRef.current;
    if (!maskCanvas) return;

    const maskCtx = maskCanvasRef.current.getContext('2d', { willReadFrequently: true });
    maskCtx.clearRect(0, 0, maskCanvas.width, maskCanvas.height);

    saveToHistory();
    drawCanvas();
  }, [drawCanvas, saveToHistory]);

  /**
   * Pan/zoom
   */
  const handleZoom = useCallback((delta, centerX, centerY) => {
    const newZoom = Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, zoom + delta));
    if (newZoom === zoom) return;

    const { x: imageX, y: imageY } = getCanvasCoords(centerX, centerY);

    // We want (imageX, imageY) to remain under cursor after zoom
    const screenXBefore = (imageX - pan.x) * zoom;
    const screenYBefore = (imageY - pan.y) * zoom;

    const newPanX = imageX - (screenXBefore / newZoom);
    const newPanY = imageY - (screenYBefore / newZoom);

    setZoom(newZoom);
    setPan({ x: newPanX, y: newPanY });
  }, [zoom, pan, getCanvasCoords]);

  const resetZoomAndPan = useCallback(() => {
    setZoom(1);
    setPan({ x: 0, y: 0 });
  }, []);

  /**
   * Brush painting
   */
  const startDrawing = useCallback((clientX, clientY) => {
    if (activeTool !== 'brush' && activeTool !== 'eraser') return;

    const maskCanvas = maskCanvasRef.current;
    if (!maskCanvas) return;
    const maskCtx = maskCanvas.getContext('2d', { willReadFrequently: true });

    const { x, y } = getCanvasCoords(clientX, clientY);
    maskCtx.beginPath();
    maskCtx.moveTo(x, y);
    maskCtx.lineWidth = brushSize;
    maskCtx.lineCap = 'round';
    maskCtx.globalCompositeOperation = (activeTool === 'eraser')
      ? 'destination-out'
      : 'source-over';
    maskCtx.strokeStyle = 'rgba(255,255,255,1)';

    // Initialize last point for interpolation
    lastPointRef.current = { x, y };

    setIsDrawing(true);
    setIsBrushVisible(true);
    setBrushPosition({ x: clientX, y: clientY });
  }, [activeTool, brushSize, getCanvasCoords]);

  // Track last point for interpolation
  const lastPointRef = useRef({ x: 0, y: 0 });

  const drawStroke = useCallback((clientX, clientY) => {
    if (!isDrawing) return;
    const maskCanvas = maskCanvasRef.current;
    if (!maskCanvas) return;
    const maskCtx = maskCanvas.getContext('2d', { willReadFrequently: true });

    const { x, y } = getCanvasCoords(clientX, clientY);
    const lastPoint = lastPointRef.current;

    // Calculate distance between points
    const dx = x - lastPoint.x;
    const dy = y - lastPoint.y;
    const distance = Math.sqrt(dx * dx + dy * dy);

    // If points are far apart, interpolate between them
    if (distance > brushSize / 4) {
      const steps = Math.ceil(distance / (brushSize / 4));
      for (let i = 1; i <= steps; i++) {
        const t = i / steps;
        const ix = lastPoint.x + dx * t;
        const iy = lastPoint.y + dy * t;
        maskCtx.lineTo(ix, iy);
        maskCtx.stroke();
        maskCtx.beginPath();
        maskCtx.moveTo(ix, iy);
      }
    } else {
      maskCtx.lineTo(x, y);
      maskCtx.stroke();
    }

    // Update last point
    lastPointRef.current = { x, y };
    setBrushPosition({ x: clientX, y: clientY });
    drawCanvas(); // update view live
  }, [isDrawing, getCanvasCoords, drawCanvas, brushSize]);

  const stopDrawing = useCallback(() => {
    if (!isDrawing) return;
    const maskCanvas = maskCanvasRef.current;
    if (!maskCanvas) return;

    const maskCtx = maskCanvas.getContext('2d', { willReadFrequently: true });
    maskCtx.closePath();

    setIsDrawing(false);
    setIsBrushVisible(false);

    saveToHistory();
    drawCanvas();
  }, [isDrawing, drawCanvas, saveToHistory]);

  /**
   * Pan dragging
   */
  const startPanning = useCallback((clientX, clientY) => {
    if (activeTool !== 'grab') return;
    setIsPanning(true);
    setLastPanPos({ x: clientX, y: clientY });
  }, [activeTool]);

  const handlePan = useCallback((clientX, clientY) => {
    if (!isPanning || !lastPanPos) return;
    
    const dx = (clientX - lastPanPos.x);
    const dy = (clientY - lastPanPos.y);

    setPan((prev) => ({
      x: prev.x - dx,
      y: prev.y - dy
    }));

    setLastPanPos({ x: clientX, y: clientY });
  }, [isPanning, lastPanPos]);

  const stopPanning = useCallback(() => {
    setIsPanning(false);
    setLastPanPos(null);
  }, []);

  /**
   * Reset entire state
   */
  const resetState = useCallback(() => {
    setPrompt('');
    setActiveTool('brush');
    setBrushSize(50);
    setIsDrawing(false);
    setIsBrushVisible(false);
    setHistory([]);
    setHistoryIndex(-1);
    setZoom(1);
    setPan({ x: 0, y: 0 });
    setIsPanning(false);
    setLastPanPos(null);
    setIsLoading(false);

    if (maskCanvasRef.current) {
      const maskCtx = maskCanvasRef.current.getContext('2d', { willReadFrequently: true });
      maskCtx.clearRect(0, 0, maskCanvasRef.current.width, maskCanvasRef.current.height);
    }
  }, []);

  /**
   * Submit the mask + prompt to your back end
   */
  const handleSubmit = useCallback(async () => {
    if (!prompt.trim()) return;
    setIsLoading(true);

    try {
      const maskCanvas = maskCanvasRef.current;
      if (!maskCanvas) return;

      // Fill black background, then draw the white mask
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = maskCanvas.width;
      tempCanvas.height = maskCanvas.height;
      const tempCtx = tempCanvas.getContext('2d', { willReadFrequently: true });

      tempCtx.fillStyle = 'black';
      tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
      tempCtx.drawImage(maskCanvas, 0, 0);

      const maskUrl = tempCanvas.toDataURL('image/png', 1.0);
      await onSubmit({ prompt, maskUrl });
    } catch (err) {
      console.error('Failed to submit inpainting:', err);
    } finally {
      setIsLoading(false);
    }
  }, [prompt, onSubmit]);

  // Handle touch events with proper passive: false option
  useEffect(() => {
    const canvas = displayCanvasRef.current;
    if (!canvas) return;

    const handleTouchStart = (e) => {
      if (activeTool === 'brush' || activeTool === 'eraser') {
        e.preventDefault();
      }
      
      if (e.touches.length === 1) {
        const touch = e.touches[0];
        if (activeTool === 'grab') {
          startPanning(touch.clientX, touch.clientY);
        } else if (activeTool === 'brush' || activeTool === 'eraser') {
          startDrawing(touch.clientX, touch.clientY);
        }
      }
    };

    const handleTouchMove = (e) => {
      if (activeTool === 'brush' || activeTool === 'eraser') {
        e.preventDefault();
      }
      
      if (e.touches.length === 1) {
        const touch = e.touches[0];
        if (activeTool === 'grab' && isPanning) {
          handlePan(touch.clientX, touch.clientY);
        } else if ((activeTool === 'brush' || activeTool === 'eraser') && isDrawing) {
          drawStroke(touch.clientX, touch.clientY);
          setBrushPosition({ x: touch.clientX, y: touch.clientY });
        }
      }
    };

    const handleTouchEnd = () => {
      stopDrawing();
      stopPanning();
    };

    const handleTouchCancel = handleTouchEnd;

    canvas.addEventListener('touchstart', handleTouchStart, { passive: false });
    canvas.addEventListener('touchmove', handleTouchMove, { passive: false });
    canvas.addEventListener('touchend', handleTouchEnd);
    canvas.addEventListener('touchcancel', handleTouchCancel);

    return () => {
      if (!canvas) return;
      canvas.removeEventListener('touchstart', handleTouchStart);
      canvas.removeEventListener('touchmove', handleTouchMove);
      canvas.removeEventListener('touchend', handleTouchEnd);
      canvas.removeEventListener('touchcancel', handleTouchCancel);
    };
  }, [
    activeTool,
    isDrawing,
    isPanning,
    startPanning,
    startDrawing,
    handlePan,
    drawStroke,
    stopDrawing,
    stopPanning,
    setBrushPosition
  ]);

  return (
    <Stack spacing={2}>
      {/* Toolbar with two groups: Mask Tools & Zoom Tools */}
      <ToolbarPaper elevation={1}>
        {/* -- Mask Tools Group -- */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Tooltip title="Brush Tool (B)">
            <IconButton 
              onClick={() => setActiveTool('brush')}
              color={activeTool === 'brush' ? 'primary' : 'default'}
            >
              <BrushIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Eraser Tool (E)">
            <IconButton 
              onClick={() => setActiveTool('eraser')}
              color={activeTool === 'eraser' ? 'primary' : 'default'}
            >
              <EraserIcon />
            </IconButton>
          </Tooltip>

          {/* Brush Size Input (only visible when brush or eraser is active) */}
          {(activeTool === 'brush' || activeTool === 'eraser') && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body2" color="text.secondary">
                Size:
              </Typography>
              <Slider
                value={brushSize}
                onChange={(_, value) => setBrushSize(value)}
                min={1}
                max={100}
                step={1}
                sx={{ width: 100 }}
                size="small"
              />
            </Box>
          )}

          <Tooltip title="Undo (Ctrl/⌘+Z)">
            <span>
              <IconButton onClick={undo} disabled={historyIndex <= 0}>
                <UndoIcon />
              </IconButton>
            </span>
          </Tooltip>

         <Tooltip title="Clear Mask (Esc)">
            <IconButton onClick={clearCanvas}>
              <ReplayIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {/* -- Zoom Tools Group -- */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 'auto' }}>
          <Tooltip title="Grab Tool (G)">
            <IconButton 
              onClick={() => setActiveTool('grab')}
              color={activeTool === 'grab' ? 'primary' : 'default'}
            >
              <PanToolIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Zoom In (+)">
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                if (!displayCanvasRef.current) return;
                const rect = displayCanvasRef.current.getBoundingClientRect();
                handleZoom(ZOOM_STEP, rect.left + rect.width / 2, rect.top + rect.height / 2);
              }}
            >
              <ZoomInIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Zoom Out (-)">
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                if (!displayCanvasRef.current) return;
                const rect = displayCanvasRef.current.getBoundingClientRect();
                handleZoom(-ZOOM_STEP, rect.left + rect.width / 2, rect.top + rect.height / 2);
              }}
            >
              <ZoomOutIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Reset View (0)">
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                resetZoomAndPan();
              }}
            >
              <RestartAltIcon />
            </IconButton>
          </Tooltip>

          <Typography variant="body2" sx={{ minWidth: 45 }}>
            {Math.round(zoom * 100)}%
          </Typography>
        </Box>
      </ToolbarPaper>

      {/* Main Canvas Container */}
      <CanvasContainer>
        {/* Hidden mask canvas (stores white strokes) */}
        <canvas
          ref={maskCanvasRef}
          style={{ display: 'none' }}
        />

        {/* Visible display canvas (image + mask) */}
        <DisplayCanvas
          ref={displayCanvasRef}
          tool={activeTool}
          isPanning={isPanning}
          // Mouse events
          onMouseDown={(e) => {
            if (activeTool === 'grab') {
              startPanning(e.clientX, e.clientY);
            } else if (activeTool === 'brush' || activeTool === 'eraser') {
              e.preventDefault(); // Prevent scrolling when starting to draw
              startDrawing(e.clientX, e.clientY);
            }
          }}
          onMouseMove={(e) => {
            if (activeTool === 'grab' && isPanning) {
              handlePan(e.clientX, e.clientY);
            } else if ((activeTool === 'brush' || activeTool === 'eraser') && isDrawing) {
              e.preventDefault(); // Prevent scrolling while drawing
              drawStroke(e.clientX, e.clientY);
            }
            // Update brush cursor position
            setBrushPosition({ x: e.clientX, y: e.clientY });
          }}
          onMouseUp={() => {
            stopDrawing();
            stopPanning();
          }}
          onMouseLeave={() => {
            stopDrawing();
            stopPanning();
            setIsBrushVisible(false);
          }}
          onMouseEnter={() => {
            setIsBrushVisible(activeTool === 'brush' || activeTool === 'eraser');
          }}
          // DO NOT prevent default wheel scrolling
          // (omitting onWheel altogether so page scrolling is not interrupted)
        />

        {/* Circular brush-preview element */}
        <BrushPreview
          size={brushSize}
          visible={isBrushVisible && (activeTool === 'brush' || activeTool === 'eraser')}
          style={{ left: brushPosition.x, top: brushPosition.y }}
        />
      </CanvasContainer>

      {/* Prompt and submit button */}
      <Paper sx={{ p: 2 }} elevation={1}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            multiline
            rows={2}
            label="Replace the highlighted area with..."
            placeholder="Describe what should replace the highlighted area"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            disabled={isLoading}
          />
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!prompt.trim() || isLoading}
            startIcon={isLoading && <CircularProgress size={20} />}
          >
            {isLoading ? 'Processing...' : 'Generate Inpainting'}
          </Button>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default InpaintingTool;
