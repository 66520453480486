import React from 'react';
import { Box, Paper, Grid, Typography, Stack, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const features = [
  {
    icon: <SettingsSuggestRoundedIcon sx={{ color: 'primary.light' }} />,
    title: 'Generate eInvoice Data',
    description:
      'Generate LHDN-compliant invoices and submit to Malaysia Hasil sandbox server',
  },
  {
    icon: <ConstructionRoundedIcon sx={{ color: 'primary.light' }} />,
    title: 'Generate AI Images',
    description: 'Play around with AI image generation and editing',
  },
  {
    icon: <ThumbUpAltRoundedIcon sx={{ color: 'primary.light' }} />,
    title: 'More coming soon!',
    description: 'Digital marketing tools for content and automation',
  },
];

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  maxWidth: '450px',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
}));

const AuthLayout = ({ children }) => {
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      {/* Left side - Features */}
      <Grid
        item
        xs={false}
        md={7}
        sx={{
          position: 'relative',
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.03),
          display: { xs: 'none', md: 'flex' },
          flexDirection: 'column',
          justifyContent: 'center',
          px: 8,
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            mb: 2,
          }}
        >
          FlowGolem
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            color: 'text.secondary',
            mb: 4,
          }}
        >
          Useful tools built by Alex Halfborg
        </Typography>
        {features.map((feature, index) => (
          <Stack key={index} direction="row" sx={{ gap: 2, mb: 3 }}>
            {feature.icon}
            <div>
              <Typography variant="subtitle2" gutterBottom>
                {feature.title}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {feature.description}
              </Typography>
            </div>
          </Stack>
        ))}
      </Grid>

      {/* Right side - Auth Form */}
      <Grid
        item
        xs={12}
        md={5}
        component={Box}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: { xs: 2, sm: 4 },
          position: 'relative',
          '&::before': {
            content: '""',
            display: { xs: 'block', md: 'none' },
            position: 'absolute',
            zIndex: -1,
            inset: 0,
            backgroundImage: (theme) => 
              `radial-gradient(ellipse at 50% 50%, ${theme.palette.primary.light}, ${theme.palette.background.default})`,
            backgroundRepeat: 'no-repeat',
            opacity: 0.1,
          },
        }}
      >
        <StyledPaper variant="outlined">
          {children}
        </StyledPaper>
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
