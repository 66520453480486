import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Switch,
  FormControlLabel,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slider,
  IconButton,
  InputAdornment
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CasinoIcon from '@mui/icons-material/Casino';

const IMAGE_SIZE_OPTIONS = [
  { value: 'square_hd', label: 'Square HD' },
  { value: 'square', label: 'Square' },
  { value: 'portrait_4_3', label: 'Portrait 4:3' },
  { value: 'portrait_16_9', label: 'Portrait 16:9' },
  { value: 'landscape_4_3', label: 'Landscape 4:3' },
  { value: 'landscape_16_9', label: 'Landscape 16:9' }
];

const AdvancedParams = ({ params, onParamChange }) => {
  const handleNumberChange = (paramName, min, max) => (event) => {
    const value = Number(event.target.value);
    if (!isNaN(value) && value >= min && value <= max) {
      onParamChange(paramName, value);
    }
  };

  const handleSwitchChange = (paramName) => (event) => {
    onParamChange(paramName, event.target.checked);
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Advanced Parameters</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' } }}>
          <FormControl fullWidth>
            <InputLabel>Image Size</InputLabel>
            <Select
              value={params.image_size}
              label="Image Size"
              onChange={(e) => onParamChange('image_size', e.target.value)}
            >
              {IMAGE_SIZE_OPTIONS.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box>
            <TextField
              label="Seed (Optional)"
              type="number"
              value={params.seed || ''}
              onChange={handleNumberChange('seed', 1, 4294967295)}
              helperText="Leave blank for random seed. Or used a fixed number for consistent results"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        const randomSeed = Math.floor(Math.random() * 4294967295) + 1;
                        onParamChange('seed', randomSeed);
                      }}
                      edge="end"
                    >
                      <CasinoIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={{ mx: 2 }}>
            <Typography gutterBottom>
              Iterations (How many times should the AI polish the image. Too high amount may cause unwanted results e.g. shiny skin)
            </Typography>
            
            <Slider
              value={params.num_inference_steps}
              onChange={(_, value) => onParamChange('num_inference_steps', value)}
              min={10}
              max={50}
              marks={[
                { value: 10, label: 'Low' },
                { value: 28, label: 'Balanced' },
                { value: 50, label: 'High' }
              ]}
              valueLabelDisplay="auto"
              sx={{
                '& .MuiSlider-markLabel': {
                  fontSize: '0.6rem', // Adjust this for label size
                  color: 'text.secondary', // Optionally make the label color subtle
                },
              }}  
            />
          </Box>

          

          <Box sx={{ mx: 2 }}>
            <Typography gutterBottom>
              Prompt Strength (how closely you want the AI to follow your instructions)
            </Typography>
            <Slider
              value={params.guidance_scale}
              onChange={(_, value) => onParamChange('guidance_scale', value)}
              min={1}
              max={16}
              step={0.1}
              marks={[
                { value: 1, label: 'Low' },
                { value: 3.5, label: 'Balanced' },
                { value: 10, label: 'High' },
                { value: 16, label: 'Very high' }
              ]}
              valueLabelDisplay="auto"
              sx={{
                '& .MuiSlider-markLabel': {
                  fontSize: '0.6rem', // Adjust this for label size
                  color: 'text.secondary', // Optionally make the label color subtle
                },
              }}              
            />

          </Box>



          {/* <TextField
            label="Number of Images"
            type="number"
            value={params.num_images}
            onChange={handleNumberChange('num_images', 1, 4)}
            helperText="Range: 1-4 (Default: 1)"
            fullWidth
          />
          */ }

          { /*
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={params.sync_mode}
                  onChange={handleSwitchChange('sync_mode')}
                />
              }
              label="Sync Mode"
            />
            <Typography variant="caption" display="block" color="text.secondary">
              Wait for image before response
            </Typography>
          </Box>
          */ }

          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={params.enable_safety_checker}
                  onChange={handleSwitchChange('enable_safety_checker')}
                />
              }
              label="Safety Filter"
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AdvancedParams;
