import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
  Divider,
  Stack,
  Avatar,
  useTheme,
  styled,
  alpha,
  Fab,
} from '@mui/material';
import {
  HomeRounded as HomeIcon,
  SettingsRounded as SettingsIcon,
  LogoutRounded as LogoutIcon,
  LoginRounded as LoginIcon,
  DescriptionRounded as DescriptionIcon,
  BusinessRounded as BusinessIcon,
  AccountBalanceRounded as AccountBalanceIcon,
  RefreshRounded as RefreshIcon,
  Menu as MenuIcon,
  VpnKey as KeyIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';

const drawerWidth = 240;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: 'border-box',
  [`& .MuiDrawer-paper`]: {
    width: drawerWidth,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.paper,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  margin: '2px 8px',
  padding: '8px 16px',
  '&.Mui-selected': {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const MobileMenuButton = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  top: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: theme.zIndex.drawer + 2,
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const Sidebar = ({ mobileOpen, handleDrawerToggle }) => {
  const { user, creditBalance, fetchCreditBalance, signOut } = useAuth();
  const [einvoisExpanded, setEinvoisExpanded] = React.useState(false);
  const [aiImageExpanded, setAiImageExpanded] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const handleLogout = async () => {
    await signOut();
    navigate('/login');
  };

  const handleRefreshBalance = async (e) => {
    e.stopPropagation();
    await fetchCreditBalance();
  };

  const isCurrentPath = (path) => location.pathname === path;

  const drawerContent = (
    <Stack sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          mt: 1,
          p: 2,
        }}
      >
        <Typography variant="h5" noWrap component="div" sx={{ fontWeight: 600 }}>
          FlowGolem
        </Typography>
      </Box>
      
      <Divider />
      
      <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
        <List dense>
          <ListItem disablePadding>
            <StyledListItemButton
              selected={isCurrentPath('/')}
              onClick={() => navigate('/')}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </StyledListItemButton>
          </ListItem>

          {user && (
            <>
              <ListItem disablePadding>
                <StyledListItemButton onClick={() => setEinvoisExpanded(!einvoisExpanded)}>
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary="e-Invois" />
                  {einvoisExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </StyledListItemButton>
              </ListItem>

              {einvoisExpanded && (
                <>
                  <ListItem disablePadding>
                    <StyledListItemButton
                      selected={isCurrentPath('/einvois/generate-einvois')}
                      onClick={() => navigate('/einvois/generate-einvois')}
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon>
                        <DescriptionIcon />
                      </ListItemIcon>
                      <ListItemText primary="Generate e-Invois" />
                    </StyledListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <StyledListItemButton
                      selected={isCurrentPath('/einvois/manage-suppliers')}
                      onClick={() => navigate('/einvois/manage-suppliers')}
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon>
                        <BusinessIcon />
                      </ListItemIcon>
                      <ListItemText primary="Manage Suppliers" />
                    </StyledListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <StyledListItemButton
                      selected={isCurrentPath('/einvois/manage-customers')}
                      onClick={() => navigate('/einvois/manage-customers')}
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon>
                        <BusinessIcon />
                      </ListItemIcon>
                      <ListItemText primary="Manage Customers" />
                    </StyledListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <StyledListItemButton
                      selected={isCurrentPath('/einvois/manage-api-keys')}
                      onClick={() => navigate('/einvois/manage-api-keys')}
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon>
                        <KeyIcon />
                      </ListItemIcon>
                      <ListItemText primary="Manage API Keys" />
                    </StyledListItemButton>
                  </ListItem>
                </>
              )}

              <ListItem disablePadding>
                <StyledListItemButton onClick={() => setAiImageExpanded(!aiImageExpanded)}>
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary="AI Image" />
                  {aiImageExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </StyledListItemButton>
              </ListItem>

              {aiImageExpanded && (
                <ListItem disablePadding>
                  <StyledListItemButton
                    selected={isCurrentPath('/image-generation')}
                    onClick={() => navigate('/image-generation')}
                    sx={{ pl: 4 }}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Generate Image" />
                  </StyledListItemButton>
                </ListItem>
              )}
            </>
          )}
        </List>

        <List dense>
          {user ? (
            <>
              <ListItem disablePadding>
                <StyledListItemButton
                  selected={isCurrentPath('/settings')}
                  onClick={() => navigate('/settings')}
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </StyledListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <StyledListItemButton
                  selected={isCurrentPath('/billing')}
                  onClick={() => navigate('/billing')}
                >
                  <ListItemIcon>
                    <AccountBalanceIcon />
                  </ListItemIcon>
                  <ListItemText 
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <span>Credits: {creditBalance}</span>
                        <IconButton
                          size="small"
                          onClick={handleRefreshBalance}
                          sx={{ ml: 1 }}
                        >
                          <RefreshIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    }
                  />
                </StyledListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <StyledListItemButton onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </StyledListItemButton>
              </ListItem>
            </>
          ) : (
            <ListItem disablePadding>
              <StyledListItemButton onClick={() => navigate('/login')}>
                <ListItemIcon>
                  <LoginIcon />
                </ListItemIcon>
                <ListItemText primary="Login" />
              </StyledListItemButton>
            </ListItem>
          )}
        </List>
      </Stack>

      {user && (
        <Stack
          direction="row"
          sx={{
            p: 2,
            gap: 1,
            alignItems: 'center',
            borderTop: `1px solid ${theme.palette.divider}`,
            backgroundColor: alpha(theme.palette.primary.main, 0.04),
          }}
        >
          <Avatar
            sx={{
              width: 36,
              height: 36,
              bgcolor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }}
          >
            {user.email?.[0]?.toUpperCase()}
          </Avatar>
          <Box sx={{ mr: 'auto' }}>
            <Typography variant="subtitle2" sx={{ lineHeight: '16px' }}>
              {user.email?.split('@')[0]}
            </Typography>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {user.email}
            </Typography>
          </Box>
        </Stack>
      )}
    </Stack>
  );

  return (
    <>
      <MobileMenuButton
        color="primary"
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        size="small"
      >
        <MenuIcon />
      </MobileMenuButton>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        {/* Mobile drawer */}
        <StyledDrawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
          }}
        >
          {drawerContent}
        </StyledDrawer>
        
        {/* Desktop drawer */}
        <StyledDrawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
          }}
          open
        >
          {drawerContent}
        </StyledDrawer>
      </Box>
    </>
  );
};

export default Sidebar;
