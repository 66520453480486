import { apiRequest } from '../../../utils/apiConfig';

const GENERATE_IMAGE_TOOL_SLUG = 'txt-to-img-flux-1-dev';

export const generateImage = async (params) => {
  try {
    const response = await apiRequest('/api/generate-image/text-to-image', {
      method: 'POST',
      body: {
        prompt: params.prompt,
        image_size: params.image_size,
        num_inference_steps: params.num_inference_steps,
        seed: params.seed,
        guidance_scale: params.guidance_scale,
        sync_mode: params.sync_mode,
        num_images: params.num_images,
        enable_safety_checker: params.enable_safety_checker,
        toolSlug: GENERATE_IMAGE_TOOL_SLUG
      }
    });

    // apiRequest now returns { success: true, data } directly
    return response.data;
  } catch (error) {
    // Handle 402 Payment Required status
    if (error.response?.status === 402) {
      const message = error.response.data?.message || 'Insufficient credits. Please purchase more credits to continue.';
      // Make the message more user-friendly by formatting it nicely
      const friendlyMessage = message
        .replace('Error: ', '')
        .replace('You need at least', 'You currently need')
        .replace('to use this tool', 'to generate an image. Please purchase more credits to continue.');
      throw new Error(friendlyMessage);
    }

    // For any other errors, just propagate them
    throw error;
  }
};

export const checkGenerationStatus = async (taskId, requestId) => {
  try {
    console.log(`Checking status for task ${taskId}, request ${requestId}`);
    const response = await apiRequest(`/api/generate-image/status/${taskId}/${requestId}`, {
      method: 'GET'
    });
    console.log('Status response:', response);
    
    // If we have a completed result
    if (response.data?.status === 'completed' && response.data?.images?.[0]?.url) {
      return response.data;
    }
    
    // If we have a pending status
    if (response.data?.status === 'pending') {
      return { status: 'pending' };
    }
    
    // Default to pending if no clear status
    console.warn('No clear status in response, assuming pending');
    return { status: 'pending' };
  } catch (error) {
    // Handle API errors that indicate in-progress state
    if (error.status === 400 && 
        (error.body?.detail?.includes('in progress') || 
         error.body?.detail?.includes('not ready'))) {
      console.log('Generation still in progress');
      return { status: 'pending' };
    }

    // Handle message-based in-progress indicators
    if (error.message?.includes('not ready') || 
        error.message?.includes('in progress')) {
      console.log('Generation still in progress');
      return { status: 'pending' };
    }

    // Log and rethrow other errors
    console.error('Failed to check generation status:', error);
    throw error;
  }
};

export const getPreviousGenerations = async (page = 1, limit = 12) => {
  try {
    const response = await apiRequest('/api/generate-image/history', {
      method: 'GET',
      params: { page, limit }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch previous generations:', error);
    throw error;
  }
};

export const inpaintImage = async (imageUrl, mask, prompt) => {
  try {
    const response = await apiRequest('/api/image-generation/inpaint', {
      method: 'POST',
      body: {
        imageUrl,
        mask,
        prompt,
        toolSlug: GENERATE_IMAGE_TOOL_SLUG
      }
    });
    return response;
  } catch (error) {
    console.error('Failed to inpaint image:', error);
    throw error;
  }
};
