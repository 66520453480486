import React, { useCallback } from 'react';
import { Box, Typography, Paper, Stack, Divider } from '@mui/material';
import { useImageGeneration } from './hooks/useImageGeneration';
import { useImageGrid } from './hooks/useImageGrid';
import PromptInput from './components/PromptInput';
import ImageGrid from './components/ImageGrid';
import AdvancedParams from './components/AdvancedParams';
const GenerateImage = () => {
  const {
    params,
    isLoading: isGenerating,
    error: generationError,
    handlePromptChange,
    handleParamChange,
    handleGenerateImage
  } = useImageGeneration();

  const {
    images,
    page,
    totalPages,
    isLoading: isLoadingGrid,
    error: gridError,
    handlePageChange,
    addNewImage,
    setImages
    } = useImageGrid();

  // Handle image generation and grid updates
  const handleGenerate = useCallback(async () => {
    const queuedId = Date.now();
    
    try {
      // Add placeholder first
      const queuedImage = {
        id: queuedId,
        prompt: params.prompt,
        isQueued: true,
        createdAt: new Date().toISOString()
      };
      addNewImage(queuedImage);
      console.log('Added queued image:', queuedImage);

      // Then start generation
      await handleGenerateImage(queuedId, (newImage) => {
        // Remove queued image and add completed one
        console.log('Generation completed, replacing queued image:', newImage);
        addNewImage({
          ...newImage,
          replaceId: queuedId // Set replaceId to match the queued image's id
        });
      }).catch(error => {
        // If generation fails, remove the queued image
        console.error('Generation failed:', error);
        setImages(prevImages => prevImages.filter(img => img.id !== queuedId));
        throw error; // Re-throw to be caught by outer catch
      });
    } catch (error) {
      // Remove the queued image and show error
      setImages(prevImages => prevImages.filter(img => img.id !== queuedId));
      console.error('Generation failed:', error);
    }
  }, [handleGenerateImage, addNewImage, params.prompt, setImages]);

  return (
    <Box sx={{ p: 0 }}>
      <Stack spacing={1}>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>
            Generate Image Using AI
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            Create unique images from text descriptions using AI. Then click on a generated image to edit it using AI.
          </Typography>
          
          <Stack spacing={2}>
            <PromptInput
              prompt={params.prompt}
              error={generationError}
              isLoading={isGenerating}
              onPromptChange={handlePromptChange}
              onGenerate={handleGenerate}
            />
            <AdvancedParams 
              params={params}
              onParamChange={handleParamChange}
            />
          </Stack>
        </Paper>

        <Divider />

        <Typography variant="h5" gutterBottom>
          Previous Generations
        </Typography>

        <ImageGrid
          images={images}
          page={page}
          totalPages={totalPages}
          isLoading={isLoadingGrid}
          error={gridError}
          onPageChange={handlePageChange}
        />
      </Stack>
    </Box>
  );
};

export default GenerateImage;
