import { useState, useCallback, useRef, useEffect } from 'react';
import { generateImage, checkGenerationStatus } from '../utils/api';
import { useAuth } from '../../../context/AuthContext';
const POLLING_INTERVAL = 5000; // 5 seconds - less aggressive polling
const POLLING_TIMEOUT = 5 * 60 * 1000; // 5 minutes timeout

export const useImageGeneration = () => {
  const { fetchCreditBalance } = useAuth();
  const [params, setParams] = useState({
    prompt: '',
    image_size: 'landscape_4_3',
    num_inference_steps: 28,
    seed: undefined,
    guidance_scale: 3.5,
    sync_mode: false,
    num_images: 1,
    enable_safety_checker: true
  });
  const [generatedImage, setGeneratedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [taskInfo, setTaskInfo] = useState(null);
  const pollingInterval = useRef(null);

  const handlePromptChange = useCallback((event) => {
    setParams(prev => ({ ...prev, prompt: event.target.value }));
    if (error) setError(null);
  }, [error]);

  const handleParamChange = useCallback((paramName, value) => {
    setParams(prev => ({ ...prev, [paramName]: value }));
    if (error) setError(null);
  }, [error]);

  useEffect(() => {
    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current);
      }
    };
  }, []); // Only run cleanup on unmount

  const startPolling = useCallback((taskId, requestId, queuedId, onImageReady) => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
    }

    let isCompleting = false;
    const startTime = Date.now();
    
    pollingInterval.current = setInterval(async () => {
      // Skip if we're already processing a completion
      if (isCompleting) {
        console.log('Skipping poll as completion is in progress');
        return;
      }
      // Check for timeout
      if (Date.now() - startTime > POLLING_TIMEOUT) {
        clearInterval(pollingInterval.current);
        setError('Image generation timed out. Please try again.');
        setIsLoading(false);
        console.error('Generation timed out for task:', taskId);
        return;
      }

      try {
        const status = await checkGenerationStatus(taskId, requestId);
        console.log('Generation status:', status);

        // Handle completed status
        if (status?.status === 'completed' && status?.images?.[0]?.url) {
          // Set completing flag to prevent duplicate processing
          isCompleting = true;
          
          try {
            const imageUrl = status.images[0].url;
            const { width, height } = status.images[0];
            
            // Load the image first to ensure it's ready
            await new Promise((resolve, reject) => {
              const img = new Image();
              img.onload = resolve;
              img.onerror = reject;
              img.src = imageUrl;
            });
            
            setGeneratedImage(imageUrl);
            setIsLoading(false);
            clearInterval(pollingInterval.current);
            console.log('Image generation completed');
            
            // Refresh credit balance
            await fetchCreditBalance();
            
            // Add the new image to the grid
            const newImage = {
              id: taskId,
              prompt: status.prompt || params.prompt,
              imageUrl: imageUrl,
              width,
              height,
              createdAt: new Date().toISOString(),
              replaceId: queuedId,
              isQueued: false
            };
            console.log('Created new image:', newImage);
            onImageReady(newImage);
          } catch (error) {
            console.error('Error processing completed image:', error);
            isCompleting = false;
          }
        }
        // For pending status, just continue polling
        else {
          console.log('Generation still in progress');
        }
      } catch (err) {
        // For actual errors (not progress-related), stop polling and show error
        if (!err.message?.includes('in progress') && 
            !err.message?.includes('not ready') &&
            !(err.status === 400 && err.body?.detail?.includes('in progress'))) {
          clearInterval(pollingInterval.current);
          setError('Failed to check generation status');
          setIsLoading(false);
          console.error('Status check failed:', err);
        } else {
          console.log('Generation still in progress');
        }
      }
    }, POLLING_INTERVAL);
  }, [fetchCreditBalance, params]); // Need both fetchCreditBalance and params

  const handleGenerateImage = useCallback(async (queuedId, onImageReady) => {
    if (!params.prompt.trim()) {
      const error = new Error('Please enter a prompt');
      setError(error.message);
      throw error;
    }

    setIsLoading(true);
    setError(null);
    setGeneratedImage(null);

    try {
      // Clean up parameters before sending
      const cleanParams = {
        ...params,
        prompt: params.prompt.trim(),
        seed: params.seed || undefined // Remove seed if not set
      };
      
      const response = await generateImage(cleanParams);
      
      // Handle cache hit
      if (response.cached) {
        console.log('Cache hit:', response.message);
        setIsLoading(false);
        
        // Create completed image object
        const newImage = {
          id: response.taskId,
          prompt: response.prompt,
          imageUrl: response.images[0].url,
          width: response.images[0].width,
          height: response.images[0].height,
          createdAt: new Date().toISOString(),
          replaceId: queuedId,
          isQueued: false,
          isCached: true,
          cacheMessage: response.message
        };
        
        onImageReady(newImage);
        return;
      }
      
      // For non-cached responses, we expect taskId and requestId
      if (!response?.taskId || !response?.requestId) {
        console.error('Invalid response:', response);
        throw new Error('Invalid response from server');
      }

      const { taskId, requestId } = response;

      setTaskInfo({ taskId, requestId, queuedId }); // Store for reference
      startPolling(taskId, requestId, queuedId, onImageReady);
      console.log('Image generation started');
    } catch (err) {
      // Don't show errors for normal pending states
      if (err.status === 400 && 
          (err.body?.detail?.includes('in progress') || 
           err.body?.detail?.includes('not ready'))) {
        console.log('Generation still in progress');
        return;
      }

      if (err.message?.includes('not ready') || 
          err.message?.includes('in progress')) {
        console.log('Generation still in progress');
        return;
      }

      // For actual errors, clear loading state and set error
      setIsLoading(false);
      setError(err.message || 'Failed to generate image');
      console.error('Image generation failed:', err);
      throw err; // Re-throw to be handled by the component
    }
  }, [params, startPolling]); // Include params since it's used in the callback

  const resetState = useCallback(() => {
    setParams({
      prompt: '',
      image_size: 'landscape_4_3',
      num_inference_steps: 28,
      seed: undefined,
      guidance_scale: 3.5,
      sync_mode: false,
      num_images: 1,
      enable_safety_checker: true
    });
    setGeneratedImage(null);
    setError(null);
    setIsLoading(false);
    setTaskInfo(null);
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
    }
  }, []);

    return {
      params,
      generatedImage,
      isLoading,
      error,
      handlePromptChange,
      handleParamChange,
      handleGenerateImage,
      resetState,
      taskInfo
    };
};
