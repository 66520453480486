import { useState, useEffect, useCallback, useRef } from 'react';
import { getPreviousGenerations } from '../utils/api';

const REFRESH_INTERVAL = 10000; // Check for new completed generations every 10 seconds

export const useImageGrid = () => {
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const refreshInterval = useRef(null);
  const hasQueuedImages = useRef(false);

  const fetchImages = useCallback(async (pageNum) => {
    setIsLoading(true);
    setError(null);

    try {
      const result = await getPreviousGenerations(pageNum);
      
      // Preserve queued images when refreshing
      setImages(prevImages => {
        // Get any queued images from current state
        const queuedImages = prevImages.filter(img => img.isQueued);
        
        // Combine queued images with new results, avoiding duplicates
        const newImages = [...queuedImages];
        result.images.forEach(img => {
          // Only add if not already represented by a queued version
          if (!queuedImages.some(queued => queued.id === img.id)) {
            newImages.push(img);
          }
        });
        
        console.log(`Fetched ${result.images.length} images for page ${pageNum}, preserved ${queuedImages.length} queued`);
        return newImages;
      });
      
      setTotalPages(result.totalPages);
    } catch (err) {
      // If the error indicates no images found, treat it as an empty state rather than an error
      if (err.response?.status === 404 || err.message?.includes('not found')) {
        setImages([]);
        setError(null);
      } else {
        // For other errors, show a user-friendly message
        setError('No images yet. Create your first one!');
        console.error('Failed to fetch images:', err);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Cleanup function for the refresh interval
  useEffect(() => {
    return () => {
      if (refreshInterval.current) {
        clearInterval(refreshInterval.current);
      }
    };
  }, []);

  // Update queued images ref whenever images change
  useEffect(() => {
    hasQueuedImages.current = images.some(img => img.isQueued);
  }, [images]);

  // Initial load and refresh setup
  useEffect(() => {
    // Initial fetch
    fetchImages(page);

    // Set up periodic refresh for checking completed generations
    if (refreshInterval.current) {
      clearInterval(refreshInterval.current);
    }

    refreshInterval.current = setInterval(() => {
      // Only refresh if we're on the first page and have queued images
      if (page === 1 && hasQueuedImages.current) {
        console.log('Checking for completed generations...');
        fetchImages(1);
      }
    }, REFRESH_INTERVAL);

    return () => {
      if (refreshInterval.current) {
        clearInterval(refreshInterval.current);
      }
    };
  }, [page, fetchImages]); // Removed images from dependencies

  const handlePageChange = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  // Add new image to the grid when generated
  const addNewImage = useCallback((image) => {
    setImages(prevImages => {
      console.log('Current images:', prevImages.map(img => ({ id: img.id, isQueued: img.isQueued })));
      console.log('Operation:', image.isQueued ? 'Adding queued' : image.replaceId ? 'Replacing' : 'Adding new');
      
      if (image.isQueued) {
        // Adding a new queued image at the start
        console.log('Adding new queued image with id:', image.id);
        return [image, ...prevImages];
      }

      if (image.replaceId) {
        // Find the queued image to replace
        const queuedImageIndex = prevImages.findIndex(img => img.id === image.replaceId);
        if (queuedImageIndex === -1) {
          console.warn('Could not find queued image to replace:', image.replaceId);
          // Add at the start instead of refreshing
          return [{ ...image }, ...prevImages];
        }

        // Create new array with replaced image
        const updatedImages = [
          ...prevImages.slice(0, queuedImageIndex),
          { ...image },
          ...prevImages.slice(queuedImageIndex + 1)
        ];
        
        console.log('Replaced queued image:', {
          old: prevImages[queuedImageIndex],
          new: image
        });
        
        return updatedImages;
      }

      // If no replaceId, add at the start
      return [{ ...image }, ...prevImages];
    });
  }, []);

  return {
    images,
    page,
    totalPages,
    isLoading,
    error,
    handlePageChange,
    addNewImage,
    setImages,
    refreshImages: () => fetchImages(page)
  };
};
