import React from 'react';
import {
  Box,
  TextField,
  Button,
  Alert,
  CircularProgress
} from '@mui/material';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';

const PromptInput = ({
  prompt,
  error,
  isLoading,
  onPromptChange,
  onGenerate
}) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mb: error ? 2 : 0 }}>
        <TextField
          fullWidth
          label="Enter your image description"
          placeholder="A serene lake at sunset with mountains in the background"
          value={prompt}
          onChange={onPromptChange}
          disabled={isLoading}
          error={!!error}
          multiline
          rows={2}
          sx={{ flex: 1 }}
        />
        <Button
          variant="contained"
          onClick={onGenerate}
          disabled={isLoading || !prompt.trim()}
          sx={{
            mt:0,
            minWidth: 120,
            height: 'fit-content'
          }}
          startIcon={isLoading ? <CircularProgress size={20} /> : <ImageRoundedIcon />}
        >
          {isLoading ? 'Generating...' : 'Generate'}
        </Button>
      </Box>
      {error && (
        <Alert severity="error" sx={{ mt: 1 }}>
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default PromptInput;
