import React, { useRef, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Alert,
  Stack,
  Pagination,
  IconButton
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

const InpaintingImageGrid = ({
  images,
  isLoading,
  error,
  onImageClick,
  page = 1,
  totalPages = 1,
  onPageChange
}) => {
  const containerRef = useRef(null);

  useEffect(() => {
    let timeoutId;
    const resizeObserver = new ResizeObserver(() => {
      // Debounce the resize handling
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (containerRef.current) {
          window.dispatchEvent(new Event('resize'));
        }
      }, 100);
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      clearTimeout(timeoutId);
      resizeObserver.disconnect();
    };
  }, []);

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 2 }}>
        {error}
      </Alert>
    );
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 200
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!images.length) {
    return (
      <Paper
        sx={{
          mt: 2,
          p: 3,
          textAlign: 'center',
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark'
              ? theme.palette.background.paper
              : theme.palette.grey[50]
        }}
      >
        <Typography variant="body1" color="text.secondary">
          No edits yet. Try highlighting part of the image above!
        </Typography>
      </Paper>
    );
  }

  return (
    <Stack spacing={2}>
        <Box
    sx={{
      width: '100%',
      maxWidth: 1200, // Limit the grid's max width
      mx: 'auto', // Center the grid
    }}
  >
      <Grid container spacing={0} ref={containerRef}>
        {images.map((image) => (
          <Grid item xs={6} sm={6} md={4} lg={3} key={image.id}
          sx={{
            // Remove any default margin/padding, but add a bit to right and bottom
            marginLeft: 0,
            paddingLeft: 0,
            paddingRight:1,
            paddingBottom: 1
            }}>
            <Paper
              sx={{
                p: 0,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark'
                    ? theme.palette.background.paper
                    : theme.palette.grey[50],
                cursor: !image.isQueued ? 'pointer' : 'default',
                maxWidth: '100%',
                '&:hover': !image.isQueued ? {
                  transform: 'scale(1.02)',
                  transition: 'transform 0.2s'
                } : {}
              }}
              onClick={() => !image.isQueued && onImageClick(image)}
            >
              <Box
                sx={{
                  position: 'relative',
                  paddingTop: image.height && image.width 
                    ? `${(image.height / image.width) * 100}%`
                    : '100%',
                  width: '100%',
                  overflow: 'hidden'
                }}
              >
                {image.isQueued ? (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'dark'
                          ? 'rgba(0, 0, 0, 0.1)'
                          : 'rgba(0, 0, 0, 0.05)',
                      borderRadius: 1
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the parent onClick
                        window.open(image.imageUrl, '_blank');
                      }}
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.7)'
                        },
                        zIndex: 2
                      }}
                    >
                      <DownloadIcon />
                    </IconButton>
                    <Box
                      component="img"
                      src={image.imageUrl}
                      alt={image.prompt}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 1,
                        maxWidth: '100%'
                      }}
                      onError={(e) => {
                        e.target.style.display = 'none';
                        console.error('Failed to load image:', image.imageUrl);
                      }}
                    />
                  </>
                )}
              </Box>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{
                  mt: 1,
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {image.isOriginal ? 'Original: ' : ''}
                {image.prompt}
              </Typography>
            </Paper>
          </Grid>
          
        ))}
        
      </Grid>
      </Box>

      {totalPages > 1 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={(_, value) => onPageChange(value)}
            color="primary"
          />
        </Box>
      )}
    </Stack>
  );
};

export default InpaintingImageGrid;
