import Logger from './logger';

// Initialize logger with API Config prefix
const logger = Logger.create('API Config');

// Function to handle unauthorized access
const handleUnauthorized = () => {
  // Clear all auth-related data from localStorage
  const authKeys = ['token', 'userEmail', 'userId', 'creditBalance'];
  authKeys.forEach(key => localStorage.removeItem(key));
  
  // If we're not already on the login page, redirect there
  if (!window.location.pathname.includes('/login')) {
    // Store the current path to redirect back after login
    const currentPath = window.location.pathname + window.location.search;
    if (currentPath !== '/') {
      localStorage.setItem('redirectAfterLogin', currentPath);
    }
    
    // Redirect to login
    window.location.href = '/login';
  }
};

// Base URL for backend API
export const API_BASE_URL = process.env.REACT_APP_API_URL;

// Log the API base URL when the file is loaded
logger.info('Backend API URL:', API_BASE_URL);
logger.info('Environment API URL:', process.env.REACT_APP_API_URL);

/**
 * Build headers for API requests
 * @param {boolean} includeAuth - Whether to include authentication token
 * @param {Object} customHeaders - Additional headers to include
 * @returns {Object} Headers object
 */
export const buildHeaders = (includeAuth = true, customHeaders = {}) => {
    const headers = {
        'Content-Type': 'application/json',
        ...customHeaders
    };

    if (includeAuth && !headers['Authorization']) {
        const token = localStorage.getItem('token');
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
    }

    return headers;
};

/**
 * Helper function to build API URLs
 * @param {string} endpoint - API endpoint
 * @returns {string} Full API URL
 */
export const buildApiUrl = (endpoint) => {
    // Remove leading slash and 'api' prefix if present since API_BASE_URL already includes it
    let cleanEndpoint = endpoint.startsWith('/') ? endpoint.slice(1) : endpoint;
    cleanEndpoint = cleanEndpoint.startsWith('api/') ? cleanEndpoint.slice(4) : cleanEndpoint;
    
    const fullUrl = `${API_BASE_URL}/${cleanEndpoint}`;
    logger.info(`Constructed URL for endpoint '${endpoint}':`, fullUrl);
    return fullUrl;
};

/**
 * Make an authenticated API request
 * @param {string} endpoint - API endpoint
 * @param {Object} options - Fetch options
 * @param {boolean} requireAuth - Whether authentication is required
 * @returns {Promise<Response>} Fetch response
 */
export const apiRequest = async (endpoint, options = {}, requireAuth = true) => {
    try {
        const headers = buildHeaders(requireAuth, options.headers);
        
        const defaultOptions = {
            ...options,
            headers
        };

        if (options.body && typeof options.body === 'object') {
            defaultOptions.body = JSON.stringify(options.body);
        }

        const url = buildApiUrl(endpoint);
        logger.info(`Making ${options.method || 'GET'} request to:`, url);

        const response = await fetch(url, defaultOptions);
        
        // Check content type to determine how to parse the response
        const contentType = response.headers.get('Content-Type');
        let data;
        
        if (contentType && contentType.includes('application/xml')) {
            // For XML responses, return the raw text
            data = await response.text();
        } else {
            // For other responses (including JSON), parse as JSON
            try {
                data = await response.json();
            } catch (e) {
                // If JSON parsing fails, return the raw response
                logger.warn('Failed to parse JSON response:', e);
                data = { success: false, error: 'Invalid response format' };
            }
        }
        
        if (!response.ok) {
            logger.error(`Request to ${url} failed with status ${response.status}`);
            
            // Handle 401 Unauthorized errors
            if (response.status === 401) {
                logger.warn('Unauthorized access, redirecting to login');
                handleUnauthorized();
                throw new Error('Invalid token');
            }
            
            const error = new Error(data?.message || data?.error || `API request failed with status ${response.status}`);
            error.response = {
                status: response.status,
                data: data
            };
            throw error;
        }

        // Ensure consistent response format
        return {
            success: true,
            data: data.data || data,
            ...(data.message && { message: data.message })
        };
    } catch (error) {
        logger.error('Request Error:', error);
        // If it's already an error with response info, just rethrow it
        if (error.response) {
            throw error;
        }
        // For other errors (network etc), wrap them
        const wrappedError = new Error(error.message || 'An unexpected error occurred');
        wrappedError.originalError = error;
        throw wrappedError;
    }
};
